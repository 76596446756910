<template>
  <div class="HOME">
    <div class="Carousel">
      <Carousel></Carousel>
    </div>
    <div class="cisp-price">
      <div class="price-main">
        <div class="price-title">
          <span>赛虎学院CISP福利免费</span>
          <img src="../assets/710.png" alt="" />
          <el-divider
            ><span style="color: red; line-height: 0%">.</span></el-divider
          >
        </div>
        <div class="price-items">
          <div
            class="price-item"
            v-for="(item, index) in priceList"
            :key="index"
          >
            <img :src="item.url" alt="" />
            <span>{{ item.text }}</span>
          </div>
        </div>
        <div style="text-align: center">
          <img style="width:80%" src="../assets/erweima.png" alt="" />
        </div>
        <div class="about-saihu">
          <div class="left">
            <img src="../assets/zhengshu.png" alt="" />
          </div>
          <div class="right">
            <div style="margin-bottom: 3%">
              <div class="item-title">
                <el-divider direction="vertical"></el-divider>
                <span>什么是CISP</span>
              </div>
              <div class="item-des">
                &nbsp;&nbsp;&nbsp;&nbsp;国家注册信息安全专业人员(Certified
                Information Security Profes- sional,
                CISP),系中国信息安全测评中心依据中编办批准开展“信息安全人员培训认证”的职能而实施的信息安全专业人员资质认定,是国家对信息安全人员资质最高认可。
              </div>
            </div>
            <div>
              <div class="item-title">
                <el-divider direction="vertical"></el-divider>
                <span>CISP适合人群</span>
              </div>
              <div class="item-des">
                &nbsp;&nbsp;&nbsp;&nbsp;企业信息安全管理入员,信息安全服务提供高,IT或安全顾问人员,IT审计人员,信息安全类讲师或培训人员,信息安全事件调查人员,其他从事与信息安全相关工作的人员。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- cisp地区 -->
    <div class="cisp-address">
      <div class="left">
        <!-- <img src="../assets/address.png" alt="" /> -->
        <table>
          <thead>
            <th colspan="5">赛虎学院全国培训报考CISP地区安排</th>
          </thead>
          <tbody>
            <tr v-for="(area, areaIndex) in areas" :key="areaIndex">
              <td
                style="cursor: pointer"
                v-for="(city, index) in area"
                :key="index"
                @click="goToLink"
              >
                {{ city }}
              </td>
            </tr>
          </tbody>
        </table>
        <el-button class="animated-button" type="danger" @click="goToLink"
          >点击咨询所在地区报考详情</el-button
        >
      </div>
      <div class="right">
        <div
          style="display: flex; padding-bottom: 5%"
          v-for="(item, index) in addressList"
          :key="index"
        >
        <div style="width:40px">
          <img style="width:100%;height:100%" src="../assets/hot.png" alt="" />
        </div>
          <span>
            &nbsp;&nbsp; {{ item.text
            }}<span  v-if="item.redText" style="color: red;white-space: nowrap;">{{
              item.redText
            }} <span v-if="item.redText">{{item.lastText}}</span> </span>
            &nbsp;&nbsp;</span
          >
          <img
            style="cursor: pointer"
            @click="goToLink"
            src="../assets/msg.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 为什么选择 -->
    <div class="whychoes whychoes1">
      <div class="choes-mian">
        <div class="title">
          <span>为什么选择赛虎学院CISP</span>
          <el-divider
            ><span style="color: red; line-height: 0%; font-size: 30px"
              >.</span
            ></el-divider
          >
        </div>
        <div class="items">
          <div class="item-item" v-for="(item, index) in cispList" :key="index">
            <div class="item" :style="{ backgroundColor: item.color }">
              <div
                :class="item.key == 4 ? 'isFour' : ''"
                v-custom-directive="{ item }"
              >
                {{ item.text }}
              </div>
            </div>
            <div class="text" @click="goToLink">{{ item.text2 }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- vs -->
    <div class="whychoes">
      <div class="choes-mian">
        <div class="title">
          <span
            >赛虎学院CISP培训<span style="color: red">VS</span>部分机构</span
          >
          <el-divider
            ><span style="color: red; line-height: 0%; font-size: 30px"
              >.</span
            ></el-divider
          >
        </div>
        <div class="items item-img">
          <img src="../assets/vs.png" alt="" />
          <el-button type="danger" @click="goToLink"
            >点击了解最新活动</el-button
          >
        </div>
      </div>
    </div>
    <!-- 饼图 -->
    <div class="whychoes">
      <div class="choes-mian">
        <div class="title">
          <span
            >为什么现在信息安全从业者需要考CISP证书</span
          >
          <el-divider
            ><span style="color: red; line-height: 0%; font-size: 30px"
              >.</span
            ></el-divider
          >
        </div>
        <div class="items item-img2">
          <img src="../assets/bing.png" alt="" />
        </div>
      </div>
      <div class="choes-mian2">
        <img src="../assets/des.png" alt="" />
      </div>
      <!-- 拥有cisp -->
      <div class="getCisp">
        <div class="choes-mian3-btn">
          <el-button type="danger" @click="goToLink"
            >点击咨询您所在行业的薪酬情况</el-button
          >
        </div>
        <div class="choes-mian">
          <div class="title">
            <span>拥有CISP证书，是您进入信息安全行业最重要的一步</span>
            <el-divider
              ><span style="color: red; line-height: 0%; font-size: 30px"
                >.</span
              ></el-divider
            >
          </div>
          <div class="choes-mian2" style="cursor:pointer" @click="goToLink">
            <img  src="../assets/list.png" alt="" />
          </div>
          <div class="choes-mian3-btn check">
            <el-button type="danger" @click="goToLink"
              >查询您是否符合入行条件</el-button
            >
          </div>
        </div>
      </div>
      <!-- 环境 -->
      <div class="choes-mian2 choes-mian3" style="background-color: #f4f5f9">
        <img src="../assets/h.png" alt="" />
      </div>
      <!-- 内容 -->
      <div class="choes-mian4">
        <div class="main4">
          <div class="title">
            <span>培训课程内容安排</span>
            <el-divider
              ><span style="color: red; line-height: 0%; font-size: 30px"
                >.</span
              ></el-divider
            >
          </div>
          <div>
            <table>
              <tr>
                <th>日期</th>
                <th>时间</th>
                <th>课程内容</th>
                <th>在线报名</th>
              </tr>
              <template>
                <template v-for="(item, index) in tableList">
                  <tr :key="index">
                    <td rowspan="2">{{ item.time }}</td>
                    <td>{{ item.day1 }}</td>
                    <td>{{ item.course1 }}</td>
                    <td @click="goToLink" style="color: red; cursor: pointer">
                      <img
                        style="width: 16%"
                        src="../assets/show.png"
                        alt=""
                      />{{ item.online }}
                    </td>
                  </tr>
                  <tr v-if="item.day2" :key="index">
                    <td>{{ item.day2 }}</td>
                    <td>{{ item.course2 }}</td>
                    <td @click="goToLink" style="color: red; cursor: pointer">
                      <img
                        style="width: 16%"
                        src="../assets/show.png"
                        alt=""
                      />{{ item.online }}
                    </td>
                  </tr>
                </template>
              </template>
            </table>
          </div>
        </div>
      </div>
      <!-- 考试须知 -->
      <div class="choes-mian4 choes-mian5">
        <div class="main4">
          <div class="title">
            <span>CISP考试须知</span>
            <el-divider
              ><span style="color: red; line-height: 0%; font-size: 30px"
                >.</span
              ></el-divider
            >
          </div>
          <div>
            <table>
              <tr>
                <th>题型</th>
                <th>题量</th>
                <th>分数线</th>
              </tr>
              <tr>
                <td rowspan="2">选择题</td>
                <td>100</td>
                <td>70分及70分以上</td>
              </tr>
            </table>
          </div>
          <div class="choes-mian4-btn">
            <el-button type="danger" @click="goToLink"
              >马上咨询优惠政策</el-button
            >
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="choes-mian2 choes-mian6">
        <img src="../assets/bot.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '../layout/components/Carousel.vue'
import img1 from '../assets/811.png'
import img2 from '../assets/812.png'
import img3 from '../assets/813.png'
import img4 from '../assets/814.png'
export default {
  name: 'HOME',
  components: {
    Carousel
  },
  data() {
    return {
      areas: [
        ['北京', '四川', '辽宁', '江西', '吉林'],
        ['西藏', '河南', '浙江', '山西', '山东'],
        ['贵州', '青海', '江苏', '广东', '陕西'],
        ['天津', '甘肃', '宁夏', '安徽', '湖南'],
        ['重庆', '上海', '黑龙江', '其他', '河北'],
        ['湖北', '福建', '内蒙古', '新疆']
      ],
      priceList: [
        {
          url: img1,
          text: '价值6488元独家信息安全课程领取'
        },
        {
          url: img2,
          text: '汇聚700道CISP题库资料'
        },
        {
          url: img3,
          text: '赛虎学院整理的培训资料以及大纲'
        },
        {
          url: img4,
          text: '赛虎学院CISP试听之后在报名，降低容错率'
        }
      ],
      addressList: [
        {
          text: '1.CISP的报考条件是什么?'
        },
        {
          text: '2.CISP含金量现在如何?'
        },
        {
          text: '3.自考，函授，成考可以考吗?'
        },
        {
          text: '4.今年CISP价格会少于',
          redText: '9600',
          lastText:'吗？'
        },
        {
          text: '5.CISP题库可以免费领取么?'
        },
        {
          text: '6.CISP通过率能达',
          redText: '99%',
          lastText:'吗？'
        }
      ],
      cispList: [
        {
          text: '99%',
          text2: '通过率高，去年通过率将近百分之百',
          color: '#FF8534'
        },
        {
          text: '校企',
          text2: '211大学的校企，品质有保障',
          color: '#26C8E2'
        },
        {
          text: '7*24h           ',
          text2: '客服咨询团队节假日不休息，在线等您',
          color: '#987EC1',
          key: 2
        },
        {
          text: '经验丰富',
          text2: '聘请专业的CISP老师，讲课经验丰富',
          color: '#FF8584',
          key: 4
        },
        {
          text: '官方',
          text2: 'CISP官方授权根正苗红',
          color: '#85CA41'
        },
        {
          text: '视频回放',
          text2: '全网唯一一家具有视频回放功能的平台',
          color: '#EBC236',
          key: 4
        }
      ],
      tableList: [
        {
          time: '第一天',
          day1: '09:00 - 12:00',
          day2: '13:30 - 16:20',
          course1: '物理与网络通信安全',
          course2: '网络安全监督',
          online: '报考'
        },
        {
          time: '第二天',
          day1: '09:00 - 12:00',
          day2: '13:30 - 16:20',
          course1: '业务连续性',
          course2: '信息安全保障',
          online: '报考'
        },
        {
          time: '第三天',
          day1: '09:00 - 12:00',
          day2: '13:30 - 16:20',
          course1: '信息安全管理',
          course2: '信息安全评估',
          online: '报考'
        },
        {
          time: '第四天',
          day1: '09:00 - 12:00',
          day2: '13:30 - 16:20',
          course1: '软件安全开发',
          course2: '安全工程与运营',
          online: '报考'
        },
        {
          time: '第五天',
          day1: '09:00 - 12:00',
          day2: '13:30 - 16:20',
          course1: '信息安全支撑技术',
          course2: '计算环境安全',
          online: '报考'
        },
        {
          time: '第六天',
          day1: '待定',
          course1: 'CISP考试',
          course2: '计算环境安全',
          online: '报考'
        }
      ]
    }
  },
  directives: {
    customDirective: {
      // 钩子函数会在绑定元素的时候调用
      bind(el, binding) {
        const item = binding.value.item
        if (item.key === 2) {
          el.style.left = '26%'
        }
      }
    }
  },
  methods: {
    goToLink() {
      window.open(
        'https://tb.53kf.com/code/client/a067d48b9ab658fde6004fcdaedbbcc18/1'
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.HOME {
  .cisp-price {
    height: 1000px;
    .price-main {
      width: 1192px;
      height: 100%;
      margin: 0 auto;
      .price-title {
        text-align: center;
        padding-top: 2%;
        img {
          width: 6%;
          margin-bottom: 2%;
        }
        span {
          font-size: 40px;
          color: #21272f;
        }
      }
    }
    .price-items {
      width: 70%;
      margin: 0 auto;
      margin-top: 2%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .price-item:nth-child(4) {
        margin-top: 3% !important;
      }
      div {
        display: flex;
        flex-direction: column;
        width: 130px;
        img {
          margin-bottom: 5%;
        }
        span {
          font-size: 16px;
        }
      }
    }
    .about-saihu {
      width: 1000px;
      display: flex;
      align-items: center;
      margin-top: 56px;
      margin-left: 23%;
      .left {
        width: 40%;
        margin-right: 10%;
        img {
          width: 100%;
        }
      }
      .right {
        .item-title {
          font-size: 24px;
        }
        .item-des {
          color: #cccccc;
          font-size: 15px;
          width: 70%;
          padding-top: 2%;
          line-height: 26px;
        }
      }
    }
  }
  .cisp-address {
    height: 500px;
    width: 100%;
    background-color: #21272f;
    display: flex;
    align-items: center;
    .left {
      width: 801px;
      height: 325px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 4.5%;
      margin-bottom: 2%;
      img {
        width: 80%;
        margin-bottom: 5%;
      }
      button {
        background-color: #0586ab;
        border: 1px solid #0586ab;
        height: 46px;
        font-size: 25px;
      }
      button:hover {
        color: red;
      }
    }
    .right {
      height: 381px;
      div {
        span {
          font-size: 18px;
          color: white;
          white-space: nowrap;
        }
      }
    }
  }

  .whychoes {
    height: 800px;
    .choes-mian {
      width: 1192px;
      height: 51%;
      margin: 0 auto;
      .title {
        font-size: 30px;
        color: #0586ab;
        text-align: center;
        margin-top: 4%;
      }
      .item-img {
        img {
          width: 111%;
        }
        button {
          width: 60%;
          background-color: #0586ab;
          border: 1px solid #0586ab;
          height: 46px;
          font-size: 25px;
        }
        button:hover {
          color: red;
        }
      }
      .item-img2 {
        height: 330px !important;
        img {
          width: 100%;
        }
      }
      .items {
        width: 900px;
        height: 85%;
        margin: 0 auto;
        margin-top: 2%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .item-item {
          width: 230px;
          height: 260px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .item {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            text-align: center;
            position: relative;
            div:nth-child(3) {
              left: 26% !important;
            }
            div {
              position: absolute;
              left: 33%;
              top: 37%;
              font-size: 25px;
              font-weight: 600;
              color: white;
            }
          }
          .text {
            text-align: center;
            font-size: 16px;
            text-decoration: underline;
          }
          .text:hover {
            color: red;
            cursor: pointer;
          }
        }
      }
    }
    .choes-mian2 {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .choes-mian6 {
      margin-top: 5%;
    }
    .choes-mian4 {
      width: 100%;
      height: 600px;
      .main4 {
        width: 1192px;
        height: 100%;
        margin: 0 auto;
        .title {
          font-size: 30px;
          color: #0586ab;
          text-align: center;
          margin-top: 4%;
        }
      }
    }
    .choes-mian5 {
      height: 200px;
    }
    .choes-mian3 {
      width: 100%;
      background-color: #f4f5f9;
      text-align: center;
      img {
        width: 56%;
      }
    }
  }
  .whychoes1 {
    height: 610px;
  }
  .getCisp {
    height: 700px;
    .choes-mian3-btn {
      width: 100%;
      height: 150px;
      background-image: url('../assets/back.png');
      text-align: center;
      line-height: 150px;
      button {
        background-color: rgba(255, 255, 255, -1);
        border: 1px solid #0586ab;
        color: #0586ab;
        height: 46px;
        font-size: 25px;
      }
      button:hover {
        color: red;
      }
    }
    .check {
      background-image: none;
      height: 100px;
      line-height: 100px;
    }
    .choes-mian {
      width: 1192px;
      height: 51%;
      margin: 0 auto;
      .title {
        font-size: 30px;
        color: #0586ab;
        text-align: center;
        margin-top: 4%;
      }
    }
    .choes-mian2 {
      width: 75%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
}
.isFour {
  left: 18% !important;
  top: 38% !important;
}
.choes-mian4-btn {
  text-align: center;
  button {
    background-color: rgba(255, 255, 255, -1);
    border: 1px solid #0586ab;
    color: #0586ab;
    height: 46px;
    font-size: 25px;
  }
  button:hover {
    color: red;
  }
}
table {
  border-collapse: collapse;
  width: 75%;
  margin: 0 auto;
  margin-top: 2%;
  font-size: 16px;
  margin-bottom: 3%;
}

th,
td {
  border: 2px solid white;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #0780fe;
  color: white;
}

td {
  background-color: #daebfe;
}
::v-deep .el-divider--horizontal {
  display: block;
  height: 1px;
  width: 23%;
  margin: 24px 0;
  /* margin: 0; */
  margin: 0 auto;
  margin-top: 2%;
}
::v-deep .el-divider__text.is-center {
  height: 24px;
}
::v-deep .el-divider--vertical {
  width: 4px;
  background-color: #0586ab;
}
</style>

