import Vue from 'vue'
import VueRouter from 'vue-router'
import HOME from '@/views/HOME.vue'
import LayOut from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: LayOut,
    redirect: '/INICIO',
    children: [
      {
        path: '/INICIO',
        name: 'HOME',
        component: HOME
      }
    ]
  },
  // {
  //   path: '/index',
  //   name: 'index',
  //   component:HOME
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
