<template>
  <div class="Carousel">
    <el-carousel :interval="5000" arrow="always" height="100%">
      <el-carousel-item v-for="item in 1" :key="item">
        <div class="title">
          <div class="title1">
            <span style="font-size: 16px; color: #eec605">线上+线下 . </span>
            <span style="font-size: 22px; color: #eec605">每月开班 . </span>
            <span style="font-size: 16px; color: #eec605">多师培训 . </span>
            <span style="font-size: 22px; color: #eec605">就近培训 . </span>
          </div>
          <div class="title2">
            <span style="color: white; font-size: 50px"
              ><span style="font-size: 100px; color: #eec605">CISP</span>国 家
              注 册 信 息 安 全 专 业 人 员 培 训</span
            >
          </div>
          <div class="title3">
            <div class="title3-text">
              现在报名立即享团报优惠+每月活动周双优惠
            </div>
            <el-button type="danger" @click="goToLink">立&nbsp;即&nbsp;报&nbsp;名</el-button>
          </div>
        </div>
        <img style="width:100%;height:100%" :src="imgurl" alt="" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import imgurl from '../../assets/701.png'
export default {
  name: 'Carousel',

  data() {
    return {
      imgurl: imgurl
      //   title: [
      //     '"Con Máscash, puede comparar diferentes opciones de préstamos y solicitar el mejor producto de préstamo."',
      //     'Sobre nosotros',
      //     'Aviso de privacidad',
      //     'Preguntas Frecuentes',
      //     'Contactanos',
      //   ]
    }
  },
  methods: {
    /**
     * 点击轮播图google图标
     */
    goToLink(){
      window.open('https://tb.53kf.com/code/client/a067d48b9ab658fde6004fcdaedbbcc18/1')
    }
  }
}
</script>

<style lang="scss" scoped>
.Carousel {
  width: 100%;
  height: 10rem;
  .img {
    width: 100%;
    height: 100%;
    // position: relative;
  }
  .title {
    position: absolute;
    // left: 21%;
    width: 100%;
    top: 24%;
    display: flex;
    height: 207px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    span {
      font-weight: 600;
      font-family: 'syreg', '微软雅黑', 'Microsoft YaHei', 'Arial', 'SimSun',
        Serif;
    }
    .title3{
      text-align: center;
      position:absolute;
      top:138%;
      div{
        font-size: 24px;
        color: #E0E0E0;
        margin-bottom: 5%;
      }
    }
  }
  .el-carousel {
    width: 100% !important;
    height: 100% !important;
    .el-carousel__container {
      height: 100% !important;
    }
  }
}

::v-deep .el-button--danger {
    color: #FFF;
    background-color: #E2304A;
    border-color: #E2304A;
    width: 200px;
    font-size: 20px;
}

</style>
